import React, { useState, useEffect } from 'react'

import poster from '../images/imaginarium/poster.jpg'
import logo from '../images/icon.png'

import { useStaticQuery, graphql, Link } from 'gatsby'

const Rhizome = ({ isFromJournal }) => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulImaginariumParticipant(sort: {
                fields: name,
                order: DESC
            }){
                nodes {
                    name
                    designation
                    slug
                    image {
                        fluid {
                            src
                        }
                    }
                }
            }
        }
    `)

    const [ showInfoText, setShowInfoText ] = useState(true)

    useEffect(() => {
        isFromJournal && setShowInfoText(false)
    }, [isFromJournal])


    //filter results to create the sub rhizome nodes from
    const subRhizomeNodes = data.allContentfulImaginariumParticipant.nodes.filter(({slug}) => slug !== 'collective-reflections')

    return (

        <div id="rhizome" className="rhizome">
            <div className="node">
                {
                    showInfoText &&
                        <>
                        <div className="sub-rhizome">
                            <div className="sub-rhizome-text">
                                <p className="info-text">The Rhizome represents the ever-growing self-documentation of the Imaginarium by its participants.</p>
                                <p className="info-text">Click on a shoot to view a participant’s reflections.</p>
                                <p className="info-text"><button className="button is-info" onClick={() => setShowInfoText(false)}>Dismiss</button></p>
                            </div>
                        </div>
                        <div className="node-stem info-text-node-stem"></div>
                        </>
                }
                <div className="node-bulb" style={{backgroundImage: `url(${poster})`, cursor: 'pointer'}}
                    onClick={() => setShowInfoText(!showInfoText)}
                    onKeyPress={() => setShowInfoText(!showInfoText)}
                    role="button"
                    tabIndex={0}
                ></div>
                <div className="node-stem"></div>
            </div>
            <div className="node">
                <Link to='/imaginarium/collective-reflections' className="node">
                    <p className="has-text-centered">Collective Reflections</p>
                    <div className="node-bulb" style={{backgroundColor: '#462652', backgroundImage: `url(${logo})`}}></div>
                    <div className="node-stem"></div>
                </Link>
            </div>
            <div className="node">
                <div className="sub-rhizome rhizome">
                    {
                        subRhizomeNodes.map(({name, designation, image, slug}, index) => {
                            const subRhizomeNode = <div key={index} className="node">
                                            <p className="has-text-centered">{name}</p>
                                            <p className="has-text-centered">{designation}</p>
                                            <div className="node-bulb sub-rhizome-node-bulb" style={
                                                image ? 
                                                    {backgroundImage: `url(${image.fluid.src})`}
                                                    :
                                                    {backgroundColor: '#9A7A43', display: 'flex', justifyContent: 'center', 
                                                        alignItems: 'center'}
                                            }>
                                                {!image && <span className="is-size-2" role="img" aria-label="rocket emoji">🚀</span>}
                                            </div>
                                            <div className="node-stem sub-rhizome-node-stem"></div>
                                        </div>

                                if(!slug.includes('to-be-announced')){
                                    return <Link to={`/imaginarium/${slug}`} key={index} className="node">
                                                {subRhizomeNode}
                                            </Link>
                                } else {
                                    return subRhizomeNode
                                }
                        }
                        )
                    }
                </div>
                <div className="node-stem"></div>
            </div>
        </div>
    )
}

export default Rhizome
