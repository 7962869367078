import React, { useState } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Navbar from '../components/navbar'

import Rhizome from '../components/rhizome'

import cover from '../images/imaginarium/cover.jpg'

import { FaAngleDoubleRight, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';

const Imaginarium = ({location}) => {

    const isShowRhizome = location.state ? location.state.showRhizome : false

    const [ showRhizome, setShowRhizome ] = useState(isShowRhizome)
    const [ showFaqs, setShowFaqs ] = useState(false)

    const btnText = showRhizome ? 'Exit Rhizome' : 'Enter Rhizome'
    const rhizomeBtn = <button id="rhizome-btn" className="button is-large is-primary" onClick={() => setShowRhizome(!showRhizome)}>
                            {btnText}
                        </button>

    const showGradientClass = !showRhizome ? 'gradient imaginarium-gradient' : ''

    return (
        <Layout>
            <SEO title="The Imaginarium" />

            <Navbar />

            <section className="hero is-fullheight">
                <div className="hero-head imaginarium-header">
                    <div className="container">
                        <h1 className="title pt-3 pb-3 imaginarium-title is-size-1 is-size-3-mobile has-text-centered">
                            A-KRA IMAGINARIUM
                        </h1>
                    </div>
                </div>
                <div className={`${showGradientClass}`}>
                    <div className="hero-body rhizome-hero-body-mobile">
                        {
                            showRhizome ?

                                <Rhizome isFromJournal={ location.state && location.state.fromJournal} />

                                :

                                <div className="content is-size-7 has-text-justified has-text-left-mobile 
                                    column is-half is-offset-one-quarter imaginarium-content pt-5"
                                >
                                    <figure className="image">
                                        <img src={cover} alt="Cover for A-Kra Imaginarium" />
                                    </figure>
                                    <p className="is-size-3 has-text-primary has-text-weight-bold">
                                        [ONGOING]
                                    </p>
                                    <p>
                                        Seeking ways to nurture the culture of imagination in our creative ecosystem, 
                                        A-Kra is launching a virtual incubator, "The Imaginarium". A rhizomatic network of digital 
                                        artists worldbuilding together through the internet; rooted in our locale but connected. 
                                    </p>
                                    <p>
                                        Over 2 weeks (October 5th - October 19th), a cohort of five dreamers will takeoff through:
                                    </p>
                                    <div className="my-6">
                                        <span className="imaginarium-section-title">3 participatory workshops:</span>
                                        <ul>
                                            <li>Building Portals: Research + Development (October 5th)</li>
                                            <li>New Suns: Speculative Design (October 11th)</li>
                                            <li>Words Are Spells: Writing The Artist Statement (October 17th)</li>
                                        </ul>
                                    </div>
                                    <p className="my-6">
                                        <span className="imaginarium-section-title">Curatorial support</span>
                                        During this time, each artist will be assisted with their research/inspiration, 
                                        receive tailored feedback, and have their work documented both during the program, 
                                        via our <a href="#rhizome-btn">live journal</a>, and after, in a culminating virtual exhibition.
                                    </p>
                                    <p className="my-6">
                                        <span className="imaginarium-section-title">Public knowledge sharing</span>
                                        Following the program, we will present our process, projects, and reflections 
                                        in a public facing format. (TBD depending on each person’s preference, examples 
                                        being an IG Live Interview, Workshop, or Collective Roundtable)
                                    </p>
                                    <p className="my-6">
                                        <span style={{fontWeight: 'bold'}}>And a stipend</span>: GH₵1,200
                                    </p>
                                    {/* <a href="https://akrastudio.typeform.com/to/HXNIbi">
                                        <button className="button is-medium" style={{backgroundColor: '#FDF980', 
                                        border: 'none', color: '#462652'}}>
                                            Apply Here
                                        </button>
                                    </a>
                                    <p className="my-3">Register by September 22</p> */}

                                    <div className="imaginarium-faqs">
                                        {
                                            !showFaqs &&
                                                <button className="button is-text is-size-4 pl-1" onClick={() => {setShowFaqs(!showFaqs)}}>
                                                    Show FAQs <FaRegPlusSquare className="ml-2"/>
                                                </button>
                                        }
                                        {
                                            showFaqs &&
                                            <>
                                                <div className="mt-6">
                                                    <h2 className="is-size-3">FAQs</h2>
                                                    <h4>Who can apply?</h4>
                                                    <p>For the first iteration of this process, we are open to applications from 
                                                        new media artists living in the Greater Accra region only. New Media in this sense is any 
                                                        practice that is made for, or using, technology. Some examples 
                                                        (but not limited to), Graphic Design, Collage, Soundscapes, Digital Painting, 
                                                        Animation, Games, Virtual Reality, 3D renderings and practices we may not 
                                                        have even heard of.
                                                    </p>
                                                </div>
                                                <div>
                                                    <h4>What do you mean by rhizomatic network?</h4>
                                                    <p><a href="http://davecormier.com/edblog/2011/11/05/rhizomatic-learning-why-learn/#:~:text=Rhizomatic%20learning%20is%20a%20way,and%20shoots%20as%20it%20spreads." target="_blank" rel="noopener noreferrer">This</a> is a rhizome. 
                                                        Similarly, the imaginarium is structured like a rhizome stem, 
                                                        allowing for offshoots in different locations. While we are not ‘in person’, 
                                                        our dispersion is not a fault, but rather a core strength. In this case, the 
                                                        digital makes room for vastness.
                                                    </p>
                                                    <p>
                                                        More concretely, this looks like a self-driven creative exercise with 
                                                        group check-ins + guidance.
                                                    </p>
                                                </div>
                                                <div>
                                                    <h4>How is the stipend to be used?</h4>
                                                    <p>The stipend is to be used at your own discretion.</p>
                                                </div>
                                                <div className="mb-3">
                                                    <h4>What is expected of me?</h4>
                                                    <p>Courage, Imagination, and readiness to play. There is no need to prepare 
                                                        anything beforehand. Specific objectives will be explored+decided as a group.
                                                    </p>
                                                </div>
                                                <button className="button is-text is-size-4 pl-1" onClick={() => {setShowFaqs(!showFaqs)}}>
                                                    Hide FAQs <FaRegMinusSquare className="ml-2"/>
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="hero-footer">
                        {
                            showRhizome ?
                                <div className="container pb-5">
                                    <div className="level">
                                        <div className="level-left rhizome-footer-left-mobile">
                                            {rhizomeBtn}
                                        </div>
                                        <div className="level-right has-text-right rhizome-footer-right-mobile">
                                            <FaAngleDoubleRight className="is-size-2" id="scroll-right-arrow" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="pb-5">
                                    <div className="column is-half is-offset-one-quarter">
                                        {rhizomeBtn}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Imaginarium
